#login {
	.account-pages {
		display: flex;
		height: 100%;
		align-items: center;
	}

	#login-header {
		font-size: 2.5em;
		color: white;
	}
}