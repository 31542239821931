table.product-table {
	td {
		color: #4c4c4c !important;
	}

	tr.deleted {
		opacity: .8;

		input, select {
			pointer-events: none;
		}
	}
}

.display-head, .display-head > td {
	background-color: rgba(0,164,228, .4) !important;
	box-shadow: unset;
	--bs-table-accent-bg: none !important;
}

.display-child, .display-child > td {
	background-color: rgba(0,164,228, .2) !important;
	--bs-table-accent-bg: none !important;
}

.master-head, .master-head > td {
	background-color: rgba(34,186,70, .4) !important;
	box-shadow: unset;
	--bs-table-accent-bg: none !important;
}

.master-child, .master-child > td {
	background-color: rgba(34,186,70, .2) !important;
	--bs-table-accent-bg: none !important;
}

#table-container {
	table {
		thead {
			background-color: var(--bg-header);
			color: white;
		}
		
		thead.table-sub-header {
			background-color: var(--bs-gray);
		}

		thead.table-sub-header + tbody > tr > td {
			color: var(--bs-primary); 
		}

		th > a {
			color: white;

			&:hover {
				color: var(--bs-gray);
			}
		}

		tr.product-row:hover>* {
			background-color: white;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}
}

.card table thead {
	background-color: unset;
	color: unset;
}