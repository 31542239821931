#problems {

	#table-container {
		table {
			position: relative;
			background-color: white;

			.badge {
				padding: .45em .4em;
			}

			tr.display-head, tr.display-child {
				color: var(--bs-primary);
			}
		}
	}

	.problem-info-tooltip {
		opacity: 1 !important;

		.tooltip-inner {
			max-width: 30em;
			width: 30em;
			background: white !important;
			color: black;
		}

		.tooltip-order-info {
			text-align: left;
			font-size: 1.1em;
			color: #424242;

			.row {
				margin-bottom: .375rem;

				div:first-of-type {
					font-weight: bold;
				}

				.tooltip-image {
					height: 7em;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

		}

		.bc-title {
			font-weight: bolder;
			font-size: 1.3em;
		}

		.inspection-status.btn-warning {
			color: white;
			font-weight: bold;
		}
	}

	.loader {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
