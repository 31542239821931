#header {
    .profile-dropdown {
        min-width: 10.5em;
    }

    .nav-user {
        min-width: 10.5em;
        padding-left: 1.5em !important;
    }

    #header-title {
        font-size: 2em;
        color: white;
        font-weight: bolder;
    }

    #topbar-notifydrop span {
	    position: absolute;
	    top: 50%;
	    right: 0;
    }
}

.page-title-box {
	display: grid;
	height: 100%;
	padding-bottom: .5em;
	border-bottom: 1px solid lightgray;
	grid-template-columns: 22% auto 12%;

	.page-title {
		margin-top: .8em;
		line-height: 1.1;
		font-size: 1.8em;
	}

	#filter-container {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}

	#actions {
		display: flex;
		align-items: flex-end;
		justify-content: right;
	}

	#filter-form {
		grid-column: 1 / 4;
		.row > div {
			margin-bottom: 0.75rem;
		}
	}
}

@media (max-width: 991.98px) {
    .nav-user {
        display: none !important;
    }

	.page-title-box {
		grid-template-columns: 39% auto 20%
	}
}
