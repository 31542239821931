#inspections-pdf {

    .preview {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 600px;
        background-clip: border-box;
        border: none;
        border-radius: 0.25rem;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }

    .base {

        .pdf {
            border: 0!important;
        }

        .pdf.card {
            box-shadow: none!important;
        }

        .page {
            max-width: 820px;

            .container {
                max-width: 820px;
            }

            .badge {
                width: 7em;
                height: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .product-title {
                display: inline-grid;
                grid-template-columns: auto auto;
                align-items: center;
            }
        }

        .product-card {
            .product-container {
                display: grid;
                grid-template-columns: repeat(auto-fill, 50%);

                .see-buttons {
                    grid-column: 1 / 3;
                }
            }

            .product {
                align-self: start;


                &.tray {
                    grid-column: 1 / 3;
                }
            }

            .product-card-item {
                height: 6em;
                display: grid;
                grid-template-columns: 5em auto auto;
                grid-template-rows: repeat(3, max-content);
                grid-column-gap: 1em;
                grid-template-areas:
                    "image title click"
                    "image info click"
                    "image color click"
                    "image icon icon"
                ;

                .image {
                    grid-area: image;
                    background-size: contain !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;

                    img {
                        width: 100%;
                    }
                }

                .color {
                    grid-area: color;
                }

                .title {
                    grid-area: title;
                    display: inline-grid;
                    grid-template-columns: auto auto;
                    justify-content: start;
                }

                .info {
                    grid-area: info;
                    font-weight: 600;
                }

                .type {
                    grid-area: type;
                }

                .click {
                    display: flex;
                    justify-content: right;
                    align-items: center;
                    grid-area: click;
                    margin-top: 1.5em;

                    a {
                        text-decoration: none;
                        i {
                            color: var(--bs-body-color);
                            font-size: 1.3em;
                        }
                    }
                }

                .icon {
                    grid-area: icon;
                    text-align: center;

                    i {
                        cursor: pointer;
                        font-size: 1.3em;
                        margin-right: 13%;
                    }
                }


                .product-title {
                    font-weight: bold;
                    color: var(--bs-primary);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }

                .badge {
                    padding-top: 0.4em;
                    width: 7em;
                }

                .product-card-content {
                    padding-left: 1em;
                }
            }
        }

        .product-card .card-header, .checkpoints .card-header {
            padding: .75rem 0!important;
            background-color: unset;
            color: unset;
        }

        .product-card .card, .checkpoints .card {
            box-shadow: none!important;

            .item-status-block {
                display: flex;
                align-items: center;
            }

            .item-status-block:before {
				height: 15px;
				width: 100%;
				max-width: 15px;
				margin-right: .5em;
				content: "";
				display: inline-block;
				position: relative;
			}

            .item-status-block.approved:before {
                background-color: var(--bs-success);
            }

            .item-status-block.rejected:before {
                background-color: var(--bs-danger);
            }

            .item-status-block.pending:before {
                background-color: var(--bs-warning);
            }

            .item-status-block.not-set:before {
                background-color: gray;
            }
        }

        .card-body {
            padding: 0!important;

            .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
                font-weight: 400;
            }
        }

        .header img {
            width: 100%!important;
        }

        .row > div {
            align-self: center;
        }
    }

    .card-header {
        background-color: unset;
        color: unset;
    }
}