#messages {

	.card-body {
		padding: 1rem 1rem;
	}

	#inspection_messages {
		overflow-y: auto;
		height: 45em;

		.text-muted > span {
		    display: inline-block;
		    overflow: hidden !important;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		}
	}

	.conversation-list {
		overflow-y: auto;
		height: 40em;
	}
}
