#settings-checklist {

	#table-container {
		table {
			background-color: white;

			.badge {
				padding: .45em .4em;
			}
		}
	}

	.group {
		.fa-pencil, .fa-trash-can {
			cursor: pointer;
		}
	}

	.group-items {
		margin-top: 1em;
		padding-left: 3em;
		padding-right: 1.4em;
	}

	.item-type-badge, .item-inspection-type-badge {
		width: 100%;
		height: 60%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.8em;
	}

	.item-handle, .group-handle, .option-handle, .option-delete {
		cursor: grab;
	}

	#example-group, #example-group-item {
		display: none;
	}

	.instruction-images {
		> div {
			position: relative;
			margin-bottom: 1em;

			> span {
				display: flex;
				align-items: center;
				padding: 0.7em;
				left: 90% !important;
				cursor: pointer;
				font-size: 0.6em;
			}
		}

		img {
			width: 100%;
		}
	}

	#editing-card {
		.language-icon {
			width: 1.3em;
			opacity: .5;
			cursor: pointer;

			&.active {
				opacity: 1;
			}
		}

		.option-item .option-languages {
			display: inline-flex;
			flex-direction: column;
			vertical-align: middle;
			row-gap: 0.5em;
		}
	}
}
