#inspections-planner {

	#draggable-container {
		overflow-y: auto;
		height: 38em;
		align-content: flex-start;
	}

	.card-loader {
		display: flex;
		justify-content: center;
		position: absolute;
		z-index: 100;
		right: 0;
		left: 0;
		align-items: center;
		width: 100%;
		bottom: 0;
		background: white;
		top: 0;
		flex-direction: column;
		color: black;

		&.inner {
			background: rgba(255, 255, 255, 0.5);
		}
	}

	.fc-daygrid-event {
		padding: 5px 1px;
		border-left: 5px solid transparent !important;
		&.search-found {
			transition: all .2s ease-in-out;

			animation-name: searchFound;
			animation-duration: 2s;
			animation-iteration-count: 2;
		}
	}

	.user-initials {
		border-radius: 50%;
		justify-content: center;
		display: flex;
		align-items: end;
		padding: 0.15em 0;
	}

	.calendar-item {
		display: grid;
		grid-gap: 2px;
		grid-template-columns: 9% 1.8em 51% 23%;
		font-size: .8em;

		.progress-container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 .1em;

			.progress {
				width: 100%;
				height: 9px;
			}
		}

		i {
			vertical-align: middle;
		}
	}

	.fc-event-main {
		width: 100%;
		color: white;

		&.qc-event {
			font-size: .9em;
			display: grid;
    		grid-template-columns: 1.5em auto;
		}
	}

	.fc-today-button, .fc-button-group > button {
		text-transform: capitalize;
	}

	.fc-daygrid-body tr[role="row"] {
		height: 20%;
	}

	.tooltip:not(.create-button) {
		opacity: 1 !important;

		.tooltip-inner {
			max-width: 30em;
			width: 30em;
			background: white !important;
			color: black;
			box-shadow: 4px 4px 5px 4px rgb(114 114 114 / 67%);
			border: 1px solid rgb(161 161 161 / 73%);
		}

		.tooltip-order-info {
			text-align: left;
			font-size: 1.1em;
			color: #424242;

			.row {
				margin-bottom: .375rem;

				div:first-of-type {
					font-weight: bold;
				}

				.tooltip-image {
					height: 7em;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

		}

		.bc-title {
			font-weight: bolder;
			font-size: 1.3em;
		}

		.inspection-status.btn-warning {
			color: white;
			font-weight: bold;
		}

		// background: orange;
		// padding: 0.7em;
		// font-weight: bolder;
		// color: white;
		// text-transform: uppercase;
		// border-radius: 3%;
		// font-size: .8em;

	}

	.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: white !important;
		border-bottom-color: white !important;
	}

	.transport-icons {
		font-size: 1.1em;

		.transport-split-icon, .split-delete-icon {
			cursor: pointer;
		}
	}

	.loader {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.auto-planned {
		border-left: 5px solid var(--bs-danger) !important;
	}
}

@keyframes searchFound {
	0% {
		transform: scale(0.93);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(0.93);
	}
  }