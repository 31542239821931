#auditlogs {

	#table-container {
		table {
			background-color: white;

			.badge {
				padding: .45em .4em;
			}

			th > a {
				color: #838c96;
			}
		}
	}
}
