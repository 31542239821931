body, html {
  height: 100%;
}

main {
  min-height: 78%;
  margin-bottom: 2em;
}

.footer {
  position: relative;
}

.offcanvas {
  visibility: visible !important;
}

@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-pro/scss/solid.scss';
@import '~@fortawesome/fontawesome-pro/scss/regular.scss';
@import '~@fortawesome/fontawesome-pro/scss/light.scss';
@import '~multiple-select-js/src/scss/multiple-select';
@import './layouts/header';
@import './layouts/page';
@import './layouts/paginator';
@import './layouts/table';
@import './layouts/chat';
@import './pages/pages';
@import './vendor/select2';
@import './vendor/select';
@import './variables';
