.fab-container {
	position: fixed;
	bottom: 3em;
	right: 2em;
	cursor: pointer;
	z-index: 20;
	.fab-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4em;
		height: 4em;
		border-radius: 100%;
		background: var(--bs-primary);
		box-shadow: 6px 5px 5px #aaaaaa;
		transition: all 500ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
		i {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			font-size: 1.6em;
			color: white;
			margin-right: 0;
		}
		#chat-unread-count {
			background-color: rgb(255 64 64) !important;
			transform: translate(-85%,-32%) !important;
		}
		.loader {
			color: white;
		}
		&:not(.loading) {
			.loader {
				display: none !important;
			}
		}
	}
	.fab-button.loading {
		background: lightgray;
		i {
			display: none;
		}
	}
}
.chat-container {
	position: fixed;
	bottom: 9em;
	right: 2em;
	height: 0;
	opacity: 0;
	width: 0;
	z-index: 20;
	border: 1px solid lightgray;
	border-radius: 3%;
	overflow: hidden;
	transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	.chat-file {
		position: relative;
		.file-icon {
			font-size: 2.5em;
			text-align: center;
		}
		.file-icon.loading {
			font-size: 1em;
		}
		.file-name {
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: .8em;
		}
		.file-remove-icon {
			position: absolute;
			right: 6%;
			top: -20%;
			background: #db2828;
			border-radius: 50%;
			color: white;
			font-size: 0.8em;
			padding: 0em 0.44em;
			cursor: pointer;
			.icon {
				margin: 0;
			}
		}
	}
	.chat-file.example {
		display: none;
	}

	#chat-loader {
		height: 100%;
		display: flex;
		justify-content: center;
		background: rgb(255 255 255 / 68%);
		align-items: center;
		position: relative;

		&:not(.active) {
			display: none;
		}

		> div > div:nth-child(2) {
			color: black;
		}
	}
}
.chat-container.hidden {
	height: 0;
	width: 0;
	transition: all 400ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}
.chat-container.show {
	height: 50%;
	width: 20%;
	opacity: 1;
	transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}
#chatlist {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
}
#topmenu {
	border-bottom: 1px solid #d8dfe3;
	padding: 0.7em;
	padding-left: 1em;
	color: white;
	display: grid;
	grid-template-columns: 95% 5%;
	align-items: center;
	background-color: var(--bs-primary);
	h4 {
		margin: 0;
	}
	i {
		cursor: pointer;
		text-align: end;
	}
}
#chats {
	overflow-y: auto;
	height: 83%;
}
.chat {
	height: 5.1em;
	border-bottom: 1px solid #e7ebee;
	position: relative;
	padding: 0.5em;
	display: flex;
	align-items: center;
	&:hover {
		background: #f1f4f6;
		cursor: pointer;
	}
	.initials {
		background: lightgray;
		width: 13%;
		border-radius: 50%;
		font-weight: bold;
		text-align: center;
		height: 3.2em;
		vertical-align: super;
		display: flex;
		justify-content: center;
		align-items: center;
		i {
			margin-right: 0;
		}
	}
	.title-container {
		padding-left: 1em;
		width: 63%;
	}
	.last-message {
		font-size: 0.8em;
		color: gray;
		width: 25%;
		padding-left: .6em;
		padding-right: .8em;
		text-align: right;
	}
	.participants {
		color: #aab8c2;
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.title {
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 15px;
		color: #597a96;
	}
}
.chat.chat-new {
	background: rgb(237 237 237);
	&:hover {
		background: #f1f4f6;
		cursor: pointer;
	}
}
#search {
	background: #e3e9ed;
	height: 3em;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	i {
		margin: 0 1em;
		color: grey;
	}
}
#searchfield-chat {
	background: #e3e9ed;
	margin: .8em 0 0 00;
	border: none;
	padding: 0;
	font-size: 14px;
	font-weight: 400px;
	color: #8198ac;
	&:focus {
		outline: 0;
	}
}
#chatview {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	background: #fff;
	overflow: hidden;
	border-radius: 6px;
	#profile {
		background: var(--bs-primary)
	}
	#participants {
		background: unset;
		height: 9%;
		border-bottom: 1px solid #e7ebee;
		display: flex;
		align-items: center;
		padding: 0.3em 0.9em;
		column-gap: 0.5em;
		overflow-y: hidden;
		overflow-x: auto;
		&::-webkit-scrollbar {
			height: .3em;
		}
		.participant {
			.participant-initials {
				border-radius: 50%;
				background: lightgray;
				height: 2em;
				width: 2em;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1em;
				padding: 1.2em;
			}
		}
	}
	#participants-available {
		position: absolute;
		max-height: 0%;
		bottom: 0;
		width: 100%;
		bottom: 10%;
		background: white;
		border-top: 1px solid #e7ebee;
		transition: all 300ms cubic-bezier(0.000, 0.195, 0.990, 1.000);
		.participant {
			display: grid;
			grid-template-columns: 15% auto;
			padding: .5em 1em;
			align-items: center;
			&:hover {
				background: #f1f4f6;
				cursor: pointer;
			}
			.participant-initials {
				border-radius: 50%;
				background: lightgray;
				height: 2.5em;
				width: 2.5em;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1em;
				padding: 1.4em;
				align-self: flex-end;
			}
		}
	}
	#attachments {
		position: absolute;
		max-height: 0%;
		bottom: 0;
		width: 100%;
		bottom: 10%;
		background: white;
		border-top: 1px solid #e7ebee;
		transition: all 300ms cubic-bezier(0.000, 0.195, 0.990, 1.000);
		display: grid;
		grid-template-columns: repeat(4, 25%);
		grid-row-gap: 1em;
		padding: 0em 1em;
		&:not(.show) {
			.chat-file {
				.file-remove-icon {
					display: none;
				}
			}
		}
	}
	#participants-available.show {
		max-height: 45% !important;
	}
	#attachments.show {
		max-height: 45% !important;
		padding: .5em 1em;
	}
}
#profile {
	height: 9%;
	overflow: hidden;
	text-align: center;
	color: #fff;
	.title {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-weight: 600;
		opacity: 0;
		margin-top: 4em;
		transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	}
	.title.animate {
		opacity: 1;
		margin-top: 0;
		transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	}
}
#chat-messages {
	opacity: 0;
	margin-top: 2em;
	width: 100%;
	height: 72%;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 1em;
	padding-top: 1em;
	transition: all 200ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
	label {
		color: #aab8c2;
		font-weight: 600;
		font-size: 12px;
		text-align: center;
		margin: 15px 0;
		width: 100%;
		display: block;
	}
	div.chat-message {
		padding: 0 0 1em 1em;
		clear: both;
		margin-bottom: .2em;
		display: grid;
		grid-template-columns: 13% 66%;
		grid-column-gap: 1em;
		justify-content: left;
	}
	div.chat-message.right {
		padding: 0 1em 1em 0;
		margin-right: -1.5em;
		margin-left: 1em;
		grid-template-columns: 66% 13%;
		justify-content: right;
		.initials {
			margin-top: 0.5em;
			margin-left: 0;
			margin-right: -3.4em;
			grid-row: 1;
			grid-column: 2;
		}
		.bubble {
			border-radius: 5px 5px 0px 5px;
			grid-column: 1;
			grid-row: 1;
			justify-self: right;
		}
	}
	.chat-message {
		.initials {
			border-radius: 50%;
			background: lightgray;
			height: 2.5em;
			width: 2.5em;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1em;
			padding: 1.4em;
			align-self: flex-end;
		}
	}
}
#chat-messages.animate {
	opacity: 1;
	margin-top: 0;
}
.chat-message {
	.bubble {
		background: #f0f4f7;
		font-size: 13px;
		font-weight: 600;
		padding: 12px 13px;
		border-radius: 5px 5px 5px 0px;
		color: #8495a3;
		position: relative;
		width: fit-content;
	}
	.attachments {
		grid-column: 1 /3;
		display: grid;
		grid-template-columns: repeat(auto-fit, 25%);
		justify-content: start;
		grid-column-gap: .5em;
		margin-top: 2em;
		margin-left: 3em;
		.chat-file {
			cursor: pointer;
			color: #4e4e4e;

			:hover {
				color: #0f0f0f;
			}
		}
	}
}
.chat-message.right {
	.attachments {
		justify-content: end;
		margin-right: 3.7em;
	}
}
.bubble {
	.corner {
		position: absolute;
		width: 2px;
		height: 6px;
		left: -7px;
		bottom: 0;
		border-bottom-left-radius: 27px 0.5rem;
		border-right: 1rem solid #f0f4f7;
		transform: translate(0, 0rem);
	}
	span.message-info {
		color: #aab8c2;
		font-size: 11px;
		position: absolute;
		bottom: -22px;
		left: 0;
		text-align: left;
		width: 23em;
	}
}
div.chat-message.right {
	.corner {
		border-bottom-right-radius: 5em 3em;
		border-left: 1rem solid #f0f4f7;
		border-right: 0;
		left: auto;
		right: -5px;
	}
	.bubble {
		span.message-info {
			right: 0;
			left: unset;
			text-align: right;
			margin-right: 0em;
		}
	}
}
#sendmessage {
	height: 10%;
	border-top: 1px solid #e7ebee;
	position: absolute;
	bottom: 0;
	right: 0px;
	width: 100%;
	background: #fff;
	overflow: hidden;
	border-radius: 6px;
	#message-input {
		background: #fff;
		margin: .8em 0 0 1em;
		border: none;
		padding: 0;
		font-size: 1.1em;
		color: black;
		width: 75%;
		&:focus {
			outline: 0;
		}
	}
	button {
		width: 2em;
		height: 1.5em;
		position: absolute;
		right: 0.7em;
		top: 0.5em;
		border: none;
		background: white;
		color: gray;
		font-size: 1.3em;
		&:hover {
			cursor: pointer;
			color: black;
		}
		&:focus {
			outline: 0;
		}
	}
	#attachment {
		right: 2.2em;
	}
	.file-input {
		display: none;
	}
}
#close-chat {
	position: absolute;
	top: 0.325em;
	opacity: 0.8;
	right: 0.625em;
	width: 1.250em;
	height: 1.250em;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
}
.cx {
	background: #fff;
	position: absolute;
	width: 0px;
	top: 15px;
	right: 15px;
	height: 3px;
	transition: all 250ms ease-in-out;
}
.cy {
	background: #fff;
	position: absolute;
	width: 0px;
	top: 15px;
	right: 15px;
	height: 3px;
	transition: all 250ms ease-in-out;
}
.cx.s1 {
	right: 0;
	width: 1.250em;
	transition: all 100ms ease-out;
	right: 0;
	width: 1.250em;
	transition: all 100ms ease-out;
}
.cy.s1 {
	right: 0;
	width: 1.250em;
	transition: all 100ms ease-out;
}
.cy.s2 {
	transform: rotate(50deg);
	transition: all 100ms ease-out;
}
.cy.s3 {
	transform: rotate(45deg);
	transition: all 100ms ease-out;
}
.cx.s2 {
	transform: rotate(140deg);
	transition: all 100ms ease-out;
}
.cx.s3 {
	transform: rotate(135deg);
	transition: all 100ms ease-out;
}
#chat-message-example {
	display: none !important;
}

@media (max-width: 991.98px) {
	.chat-container.show {
		width: 50%;
	}
 }
