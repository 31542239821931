#map {
	#map-container {
		width: 100%;
		height: 75vh;
	}

	.gm-style .gm-style-iw-c {
		padding: 12px !important;
	}

	.gm-style-iw-d {
		overflow: hidden !important;
	}
}