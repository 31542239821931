.select2-container.success .select2-selection--single, .select2-dropdown.success {
    border: 1px solid var(--bs-success);
}

.select2-container.warning .select2-selection--single, .select2-dropdown.warning {
    border: 1px solid var(--bs-warning);
}

.select2-container.priority .select2-selection--single, .select2-dropdown.priority {
    border: 1px solid var(--bs-priority);
}

.select2-container.danger .select2-selection--single, .select2-dropdown.danger {
    border: 1px solid var(--bs-danger);
}

.inspection-info-tooltip, .product-info-tooltip {
    opacity: 1 !important;

    .tooltip-inner {
        background: white !important;
        color: black;
        box-shadow: 4px 4px 5px 4px rgb(114 114 114 / 67%);
        border: 1px solid rgb(161 161 161 / 73%);
    }

    .tooltip-order-info {
        text-align: left;
        font-size: 1.1em;
        color: #424242;

        .row {
            margin-bottom: .375rem;

            div:first-of-type {
                font-weight: bold;
            }

            .tooltip-image {
                height: 7em;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .tooltip-product-image {
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

    }

    .bc-title {
        font-weight: bolder;
        font-size: 1.3em;
    }

    .inspection-status.btn-warning {
        color: white;
        font-weight: bold;
    }
}

.product-info-tooltip {
    .tooltip-inner {
        max-width: 45em;
        width: 45em;
    }
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#inspection-update-form.downloaded {
    div:not(.download-exception) {
        &> {
            input:not(.download-exception), select:not(.download-exception), .select2-container .select2-selection--single:not(.download-exception) {
                background: #eef2f7;
                pointer-events: none;
            }
        }
    }
}

#inspection-update-form.order-cancelled, #inspection-update-form.manual-uploaded, #inspection-update-form.transport-deleted {
    div {
        &> {
            input, select, .select2-container .select2-selection--single {
                background: #eef2f7;
                pointer-events: none;
            }

            #pdf-fileinput-label {
                pointer-events: none;
                opacity: .65;
            }
        }
    }
}

#inspections-edit {
    #watchers-column, #approvers-column {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        grid-column-gap: 0.7em;
        row-gap: 0.5em;
    }

    #watchers-column .badge:hover, #approvers-column .badge:hover {
        cursor: pointer;
        background-color: #606e8d !important;
    }

    .in-packinglist {
        opacity: .8;
    }

    .custom-icon {
        font-size: 1.3em;
        margin-right: 0.5em;
        color: #4c4c4c;
    }
}