#inspections-show {
	.card-header, .card-body {
		padding: 1rem 1rem;
	}

	.inspection-info-tooltip, .product-info-tooltip {
	    opacity: 1 !important;

	    .tooltip-inner {
	        background: white !important;
	        color: black;
	        box-shadow: 4px 4px 5px 4px rgb(114 114 114 / 67%);
	        border: 1px solid rgb(161 161 161 / 73%);
	    }

	    .tooltip-order-info {
	        text-align: left;
	        font-size: 1.1em;
	        color: #424242;

	        .row {
	            margin-bottom: .375rem;

	            div:first-of-type {
	                font-weight: bold;
	            }

	            .tooltip-image {
	                height: 7em;
	                background-repeat: no-repeat;
	                background-size: contain;
	            }

	            .tooltip-product-image {
	                background-repeat: no-repeat;
	                background-size: contain;
	            }
	        }

	    }

	    .bc-title {
	        font-weight: bolder;
	        font-size: 1.3em;
	    }

	    .inspection-status.btn-warning {
	        color: white;
	        font-weight: bold;
	    }
	}

	.product-info-tooltip {
	    .tooltip-inner {
	        max-width: 45em;
	        width: 45em;
	    }
	}

	.loader {
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	}

	.custom-tooltip-list {
		padding-left: 1.3em;
	}

	#messages-body {
		> .row {
			position: absolute;
			width: 99%;
			padding-bottom: 1em;
		}
	}

	#checklist-collapse {
		text-align: center;
		font-size: 2em;

		a {
			color: unset;
		}
	}

	#details {
		#order-details-card {
			#detail-left-side, #detail-right-side {
				.row {
					margin-bottom: .3em;
				}
				.col-md-6:nth-child(1) {
					font-weight: bold;
				}
			}
		}

		.checkpoints.card {
    		box-shadow: none!important;
		}

		.checkpoints, #checkpoints {
			.item-status-block {
				display: flex;
				align-items: center;
			}

			.item-status-block:before {
				height: 15px;
				width: 100%;
				max-width: 15px;
				margin-right: .5em;
				content: "";
				display: inline-block;
				position: relative;
			}

			.item-status-block.approved:before {
				background-color: var(--bs-success);
			}

			.item-status-block.rejected:before {
				background-color: var(--bs-danger);
			}

			.item-status-block.pending:before {
				background-color: var(--bs-warning);
			}

			.item-status-block.not-set:before {
				background-color: gray;
			}
		}

		.measurement-table {
			td {
				padding: .25rem .95rem;
			}

			th {
				width: 32%;
			}
		}
	}

	#sidebar {
		.inspector-row {
			align-items: center;

			.status-column {
				justify-content: right;
    			display: flex;

				button {
					padding: 0.25rem 0.9rem;
				}

				&.status-column-custom {
					button {
						padding: 0.25rem 0.6rem;
					}
				}
			}
			.status-column.text-end {
				justify-content: space-between;
			}
		}
	}

	.btn-status {
		min-width: 6rem;
	}

	#map-icon {
		cursor: pointer;
		font-size: 1.1em;
		color: black;
	}

	#maps-modal {
		.modal-dialog {
			height: 90%;

			.modal-content {
				height: 100%;

				#map {
					height: 100%;
				}
			}
		}

	}

	nav.bread-crumb {
		ol.breadcrumb {
			padding: 0;
			font-size: 16px;
		}
		.breadcrumb-item+.breadcrumb-item::before {
			color: var(--bs-primary);
			float: none;
			font-weight: 600;
		}
		.breadcrumb-item a {
			color: var(--bs-primary);
			transition: all .5s ease-in-out;
		}
		.breadcrumb-item a:hover {
			font-weight: 600;
		}
		.breadcrumb-item.active a {
			color: #7f8589;
		}
	}

	.carousel-item {
		cursor: pointer;
	}

	.inspection-file {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		i {
			font-size: 3em;
			margin-bottom: .2em;

			&:hover {
				color: #666a6f;
				cursor: pointer;
			}
		}

		label {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

#inspections-list {
	#product-sidebar {
		width: 20%;
		background-color: #313a46;
		color: white;

		.offcanvas-body {
			padding: 0;
		}

		.offcanvas-body > div {
			display: grid;
			cursor: pointer;
			grid-template-columns: 75% 25%;

			&.active {
				background-color: #5b6c81
			}

			&:hover {
				background-color: #5b6c81;
			}
			div:nth-child(2) {
				text-align: right;
			}
		}
	}

	#sidebar-button {
		position: absolute;
		align-items: center;
		display: flex;
		margin-right: -3em;
		height: 100%;
		right: 0;
	}

	#canvas-close-button {
		cursor: pointer;
	}
}


button.no-comment {
    visibility: hidden;
}

#product-card {
	.product-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, 50%);

		.see-buttons {
			grid-column: 1 / 3;
		}
	}

	.product {
		align-self: start;

		&.deleted, &.in-packinglist {
			opacity: .8;
		}

		&.tray {
			grid-column: 1 / 3;
		}

		&.hide-product {
			display: none;
		}
	}

    .product-card-item {
		height: 8em;
		display: grid;
		grid-template-columns: 90px auto auto;
		grid-template-rows: 25% auto auto 20%;
		grid-column-gap: 1em;
		grid-template-areas:
			"image title click"
			"image info click"
			"image color click"
			"image icon icon"
		;

		border-bottom: 1px solid #dee2e6;

		.image {
			grid-area: image;
			background-size: contain !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
		}

		.color {
			grid-area: color;
		}

		.title {
			grid-area: title;
			display: inline-grid;
			grid-template-columns: auto auto;
			justify-content: start;
		}

		.info {
			grid-area: info;
			font-weight: 600;
		}

		.type {
			grid-area: type;
		}

		.click {
			display: flex;
			justify-content: right;
			align-items: center;
			grid-area: click;
			margin-top: 1.5em;

			a {
				text-decoration: none;
				i {
					color: var(--bs-body-color);
					font-size: 1.3em;
				}
			}
		}

		.icon {
			grid-area: icon;
			text-align: center;

			i {
				cursor: pointer;
				font-size: 1.3em;
				margin-right: 13%;
			}
		}


		.product-title {
			font-weight: bold;
			color: var(--bs-primary);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}

		.badge {
			padding-top: 0.6em;
			width: 7em;
		}

        .product-card-content {
            padding-left: 1em;
        }
    }
}

#comment-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
}