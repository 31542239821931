.select2-container .select2-selection--multiple .select2-selection__choice__remove {
    border-color: #626262;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
    background: #626262;
}

.select2-container .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-results__option[aria-selected=true]:hover, .select2-container--default .select2-results__option[aria-selected=true] {
    color: white;
    background-color: var(--bs-primary);
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 0 !important;
}
