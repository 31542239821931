#settings-roles {

	#table-container {
		table {
			background-color: white;

			.badge {
				padding: .45em .4em;
			}
		}
	}
}
