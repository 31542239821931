#pagination-nav {
	display: flex;
	justify-content: center;
	margin-top: 1.5em;

	.page-link[aria-label] {
		padding: .375rem 0.85rem;
	}
}

.page-item.active .page-link {
	background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}
