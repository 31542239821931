#inspections-combine {

	.transport-row {
		min-height: 33vh;
	}

	.combine-card {
		padding: 1.5rem 0;

		.card-title {
			padding-left: calc(var(--bs-gutter-x) * .5);
		}

		.row {
			margin: 0;
			width: 100%;
		}
		// .col-md-4 {
		// 	padding-left: 0;
		// }
		table {
			tr th:last-child, tr td:last-child {
				padding-right: .5em;
			}

			tr th:nth-child(1), tr td:nth-child(1) {
				padding-left: .5em;
			}

		// 	tr td:nth-child(1) {
		// 		padding-left: 0;
		// 	}
		}
	}
}