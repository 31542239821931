:root {
    --bs-primary: #152342;
    --bs-primary-rgb: 21, 35, 66;
    --bs-priority: #F78F1E;
    --bs-priority-rgb: 247, 143, 30;
    --fc-event-bg-color: #152342;
    --bs-breadcrumb-divider: '>';
    --bg-header: #313a46;
    --bs-body-font-size: 0.85rem;
}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #172d5e;
    border-color: #172d5e;
}

.btn-primary:focus {
    box-shadow: 0 0 0 0.15rem rgb(38 54 76 / 50%);
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.bg-priority {
    background-color: rgba(var(--bs-priority-rgb), 1) !important;
}

.btn-priority, .btn-orange {
    background-color: var(--bs-priority);
    border-color: var(--bs-priority);
    color: white;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus {
    color: white;
}

.border-danger {
    border: 1px solid var(--bs-danger);
}

.form-check-input:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.badge-outline-primary {
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary);
}

.topnav .nav-item.active > a {
    color: var(--bs-primary);
}

.input-error {
    color: red;
}

.progress-custom {
    height: 11px;
}

.daterangepicker {
    z-index: 3500;
}

.datepicker {
    padding: 7.8px !important;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-right {
    justify-content: right;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-95 {
    width: 95%;
}

.w-97 {
    width: 97%;
}

.tooltip-left .tooltip-inner {
    text-align: left;
}

.btn-status {
    padding: 0.25rem 0.6rem;
}

.order-cancelled, .transport-deleted-icon {
    color: var(--bs-danger);
}

.shorten {
    max-width: 11em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shorten-small {
    max-width: 7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-not-set {
    background-color: gray;
}

.white-tooltip {
    opacity: 1 !important;

    .tooltip-inner {
        background-color: white;
        color: var(--bs-body-color);
        text-align: left;
        border: 1px solid var(--bs-primary);
    }
}
