#inspections-list {

	table {
		position: relative;
	}

	#inspection-states {
		display: flex;
		height: 4em;
		margin-left: 1em;

		> div {
			cursor: pointer;
			width: 34%;
			background: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bolder;
		}

		:first-of-type {
			border-top-left-radius: 6%;
			border-bottom-left-radius: 6%;
		}

		:last-of-type {
			border-top-right-radius: 6%;
			border-bottom-right-radius: 6%;
		}

		.active {
			background: var(--bs-primary);
			color: white;
		}

		> div:hover {
			background: var(--bs-primary);
			color: white;
		}
	}

	#table-container {
		table {
			background-color: white;

			.badge {
				padding: .45em .4em;
			}
		}

		.progress-bar-column {
			width: 8%;
		}
	}

	.inspection-done-tooltip {
		opacity: 1 !important;

		.tooltip-inner {
			background: white !important;
			color: black;
		}
	}

	.inspection-info-tooltip, .product-info-tooltip {
		opacity: 1 !important;

		.tooltip-inner {
			background: white !important;
			color: black;
			box-shadow: 4px 4px 5px 4px rgb(114 114 114 / 67%);
			border: 1px solid rgb(161 161 161 / 73%);
		}

		.tooltip-order-info {
			text-align: left;
			font-size: 1.1em;
			color: #424242;

			.row {
				margin-bottom: .375rem;

				div:first-of-type {
					font-weight: bold;
				}

				.tooltip-image {
					height: 7em;
					background-repeat: no-repeat;
					background-size: contain;
				}

				.tooltip-product-image {
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

		}

		.bc-title {
			font-weight: bolder;
			font-size: 1.3em;
		}

		.inspection-status.btn-warning {
			color: white;
			font-weight: bold;
		}
	}

	.inspection-info-tooltip {
		.tooltip-inner {
			max-width: 30em;
			width: 30em;
		}
	}

	.product-info-tooltip {
		.tooltip-inner {
			max-width: 45em;
			width: 45em;
		}
	}

	.loader {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

nav.bread-crumb {
    ol.breadcrumb {
        padding: 0;
        font-size: 16px;
    }
    .breadcrumb-item+.breadcrumb-item::before {
        color: var(--bs-primary);
        float: none;
        font-weight: 600;
    }
    .breadcrumb-item a {
        color: var(--bs-primary);
        transition: all .5s ease-in-out;
    }
    .breadcrumb-item a:hover {
        font-weight: 600;
    }
    .breadcrumb-item.active a {
        color: #7f8589;
    }
}

div.card-body {
	div.product-card-item div.product-image {
		background: #fff;
		width: 125px;
		height: 125px;
		float: left;
		background-size: 125px;
		background-repeat: no-repeat;
		background-position: center;
	}
}


.timeline-icon {
	font-size: 0.8em !important;
}

.timeline-tooltip {
	opacity: 1 !important;

	.tooltip-inner {
		background-color: white;
		color: var(--bs-body-color);
		text-align: left;
		max-width: 24vw;
		border: 1px solid var(--bs-primary);

		#watcher-approver-column {
			ul {
				padding-left: 1rem;
			}
		}
	}
}

.problems-tooltip {
	opacity: 1 !important;

	.tooltip-inner {
		background-color: white;
		color: var(--bs-body-color);
		text-align: left;
		width: 25vw;
    	max-width: unset;
		border: 1px solid var(--bs-primary);
	}
}
