.dropdown-select {
	z-index: 100 !important;
	.list-group-item-primary {
		background-color: var(--bs-primary);
		color: white;
		border: 0;
	}

	.list-group {
		max-height: 25vh !important;
	}
}