.navbar {
	width: 100%;
}

#preloader {
	display: none;
	background-color: rgb(241 243 250 / 73%);

	.bouncing-loader div {
		background: var(--bs-primary);
	}
}

.card-header {
	background-color: var(--bg-header);
	color: white;
	// padding: 0.35rem 1.5rem;
}

.deleted-icon {
	color: var(--bs-danger);
	margin-left: .5em;
}

.image-modal img {
	width: 100%;
    object-fit: contain;
    max-height: 70vh;
}